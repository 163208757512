var prodcat = prodcat || {};

(function ($) {
  Drupal.behaviors.productPriceV1 = {
    selectors: {
      productFullClass: '.js-product-full',
      productUiClass: '.js-product-ui',
      productBundleClass: '.js-pdp-bundle-item'
    },
    attach: function (context) {
      var self = this;

      if (this.attached) {
        return;
      }
      self.attached = true;
      self.loopProducts(context);
      var $product = $('.js-product-full', context);
      var dataSkuBaseId = $product.find('.js-spp-shades-grid-item.active').attr('data-sku-base-id');

      if (typeof dataSkuBaseId !== 'undefined') {
        var $productId = $product.data('productId');

        self.updatePrice(dataSkuBaseId, context, self.selectors.productFullClass, $productId);
        self.updateInstallmentPrice(dataSkuBaseId, $product);
      }
      $(document).on('product.skuSelect', self.selectors.productFullClass, function (e, skuBaseId) {
        e.preventDefault();
        $product = $(this);
        var $dataProductId = $product.data('productId');

        self.updatePrice(skuBaseId, context, self.selectors.productFullClass, $dataProductId);
      });
      $(document).on('product.skuSelect', self.selectors.productUiClass, function (e, skuBaseId) {
        e.preventDefault();
        $product = $(this);

        // When it is enabled, no need to call updatePrice for MPP page
        if (!Drupal.settings.globals_variables.product_price_range) {
          var $dataProductId = $product.data('productId');

          self.updatePrice(skuBaseId, context, self.selectors.productUiClass, $dataProductId);
        }
        self.updateInstallmentPrice(skuBaseId, $product);
      });
      $(document).on('productBundle.skuSelect', self.selectors.productBundleClass, function (e, skuBaseId) {
        e.preventDefault();
        $product = $(this);
        var dataProductId = $product.data('productId');

        self.updatePrice(skuBaseId, context, self.selectors.productBundleClass, dataProductId);
        self.updateInstallmentPrice(skuBaseId, $product);
      });
      $(document).on('product.updateInvStatus', self.selectors.productFullClass, function () {
        $product = $(this);
        dataSkuBaseId = $product.data('skuBaseId');

        if (typeof dataSkuBaseId === 'undefined') {
          return;
        }
        self.updateInstallmentPrice(dataSkuBaseId, $product);
      });
      $(document).on('product.updateInvStatusFinished', function () {
        var $grids = $('.js-product-grid', this);

        if ($grids.length > 0) {
          self.loopProducts($grids);
        }
      });
    },

    loopProducts: function ($context) {
      var self = this;
      var $products = $('.js-product-ui', $context);

      $products.each(function () {
        var $product = $(this);
        var $skuBaseId = $product.attr('data-sku-base-id');

        if (typeof $skuBaseId !== 'undefined') {
          self.updateInstallmentPrice($skuBaseId, $product);
        }
      });
    },

    filterOutBundleItems: function ($items) {
      return $items.filter(function () {
        return $(this).closest('.js-pdp-bundle-item').length < 1;
      });
    },

    updatePrice: function (skuBaseId, context, productClass, productId) {
      const self = this;
      if (typeof skuBaseId === 'undefined') {
        return;
      }
      var $product = $(productClass + '[data-product-id="' + productId + '"]', context);
      var skuData = prodcat.data.getSku(skuBaseId);
      var $selectedShade = $product.find(
        '.js-spp-shades-grid-item[data-sku-base-id="' + skuBaseId + '"]'
      );
      var $shadeInfoPrice = $('.js-product-price-v1', $product);
      var $shadeInfoTaxPrice = $('.js-product-taxed-price-v1', $product);

      if (productClass !== self.selectors.productBundleClass) {
        // If we are not inside a bundle item, filter the prices from bundle out.
        $shadeInfoPrice = self.filterOutBundleItems($shadeInfoPrice);
        $shadeInfoTaxPrice = self.filterOutBundleItems($shadeInfoTaxPrice);
      }
      var hasShadeInfoTaxPrice = $shadeInfoTaxPrice.length > 0 ? true : false;
      var shadePrice = !!skuData ? skuData.formattedPrice : $selectedShade.data('shadePrice');
      var shadePrice2 =
        !!skuData && skuData.PRICE2 ? skuData.formattedPrice2 : $selectedShade.data('shadePrice2');
      var shadeTaxPrice = !!skuData
        ? skuData.formattedTaxedPrice
        : $selectedShade.data('shadeTaxPrice');
      var $tmpPrice = $();
      var $tmpPrice2 = $();

      hasShadeInfoTaxPrice ? null : $shadeInfoPrice.empty();

      if (shadePrice2) {
        $tmpPrice2 = $('<span>').addClass('product-price--discounted').html(shadePrice2);
        $tmpPrice = $('<span>').addClass('product-price--sale').html(shadePrice);
        $shadeInfoPrice.append($tmpPrice2).append($tmpPrice);
      } else if (shadePrice && !hasShadeInfoTaxPrice) {
        $shadeInfoPrice.html(shadePrice);
      } else {
        $shadeInfoTaxPrice.html(shadeTaxPrice);
      }
    },

    updateInstallmentPrice: function (skuBaseId, $product) {
      if (typeof skuBaseId === 'undefined') {
        return;
      }
      var skuDataL2 = prodcat.data.getSku(skuBaseId);
      var installmentPrice =
        !!skuDataL2 && skuDataL2.formattedInstallmentPrice
          ? skuDataL2.formattedInstallmentPrice
          : '';
      var $installmentPrice = $('.js-product-price-installment', $product);

      if ($installmentPrice.length) {
        $installmentPrice.html(installmentPrice);
      }
    },
    attached: false
  };
})(jQuery);
